html {
  min-height: 100%;
}

body {
  padding-top: 5rem;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.navbar {
  background-color: #09853d;
}

.navbar .dropdown-menu {
  background-color: #09853d;
  border: 0;
}

.navbar .dropdown-item {
  color: #fff;
  opacity: .5;
  letter-spacing: 1px;
  font-family: Open Sans;
  font-size: 13px;
}

.navbar .dropdown-item:hover {
  opacity: .75;
  background-color: inherit;
}

.navbar-brand {
  font-family: Marcellus;
  font-size: 30px;
  font-weight: 400;
}

.nav-item {
  letter-spacing: 1px;
  padding: 5px;
  font-family: Open Sans;
  font-size: 13px;
}

.section-hero-bg {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
  background-image: url("home.67050c13.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  top: auto !important;
}

.hero {
  width: 100%;
  height: 100vh;
  text-align: center;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-top: -3em;
  display: flex;
}

.hero-title {
  margin-top: 75px;
  margin-bottom: .5em;
  font-family: Marcellus;
  font-size: 5em;
  font-weight: 400;
}

.hero-button {
  max-width: 250px;
  text-align: center;
  letter-spacing: 2px;
  white-space: nowrap;
  background-color: #09853d;
  border: 2px solid #007e37;
  margin: 1rem auto;
  padding: 10px 0;
  display: block;
  position: sticky;
  overflow: hidden;
}

.hero-button:hover {
  color: #fff;
  background: none;
  border: 2px solid #fff;
}

.hero-button-outline {
  color: #fff;
  max-width: 250px;
  text-align: center;
  letter-spacing: 2px;
  white-space: nowrap;
  background: none;
  border: 2px solid #fff;
  margin: 1rem auto;
  padding: 10px 0;
  display: block;
  position: sticky;
  overflow: hidden;
}

.hero-button-outline:hover {
  background-color: #09853d;
  border: 2px solid #007e37;
}

.section {
  z-index: 2;
  margin: 0;
  padding: 3rem 1rem;
  position: relative;
  overflow: hidden;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-title {
  font-size: 34px;
  font-weight: 300 !important;
}

.section-title-separator {
  width: 40px;
  text-align: center;
  border: 1px solid #09853d;
  margin: 10px auto 30px;
}

.section-bg-gray {
  background-color: #f3f3f7;
}

.section-bg-dark {
  color: #fff;
  background-color: #000;
}

.text-big {
  font-size: 25px;
  font-weight: 200;
}

.text-medium {
  font-size: 18px;
  font-weight: 200;
}

.section-separator {
  padding: 100px 50px;
}

.subsection-header {
  margin-left: -15px;
  width: 100% !important;
}

.subsection-title {
  color: #666;
  margin-top: 10px;
  font-size: 28px;
}

.subsection-title-separator {
  width: 40px;
  border: 1px solid #666;
  margin-top: 10px;
  margin-bottom: 10px;
}

.subsection-body {
  margin-top: 20px;
}

.service-card {
  -o-transition: .3s;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 40px 40px 35px;
  transition: all .3s;
  overflow: hidden;
  box-shadow: 0 5px 40px #00000012;
}

.service-card-image {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  float: none;
  height: auto;
  margin: auto auto 15px;
  display: block;
}

.service-card-image img {
  float: none;
  border-radius: 4px;
}

.service-card-title {
  vertical-align: top;
  text-align: center;
  margin-bottom: 16px;
}

.service-card-body {
  text-align: left;
  color: #828282;
  text-align: justify;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  line-height: 1.6;
}

.service-card-body p {
  margin-bottom: 0;
}

.group-image-container {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.group-image-container:last-of-type {
  border-right-width: 0;
}

.section-health-insurances img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
  filter: grayscale();
  transition: all .5s;
}

.section-health-insurances img:hover {
  filter: grayscale(0%);
  transition: all .5s;
}

.subsection-team {
  padding: 0 20px;
}

.subsection-team .subsection-header {
  background-color: #fff;
  padding-left: 5px;
}

.card-team {
  padding: 0 0 40px;
}

.card-team-img {
  image-orientation: from-image;
}

.card-team-subtitle {
  opacity: .7;
  font-size: 14px;
  font-weight: 600;
}

.card-team-body {
  padding: 30px;
}

.card-team-footer {
  color: #828282;
  opacity: .7;
  margin-top: 20px;
  font-size: 14px;
}

.card-team-social-icon {
  margin-right: 20px;
}

.flip-card {
  height: 610px;
  perspective: 1000px;
  background-color: #0000;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform .8s;
  position: relative;
}

.flip-card-front, .flip-card-back {
  backface-visibility: hidden;
  position: absolute;
}

.flip-card-front {
  height: 350px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.flip-card-front .card-body {
  padding-top: 370px;
}

.flip-card-back {
  height: 100%;
  transform: rotateY(180deg);
}

.flip-card-back .card-body {
  height: 100%;
}

.section-testimonials-bg {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
  background-image: url("bg.ef7880de.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.testimonials-cards {
  z-index: 2;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.testimonials-card {
  max-width: 800px;
  padding: 0 15px;
}

.testimonials-card-message-container {
  text-align: left;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 40px;
  position: relative;
  box-shadow: 0 5px 40px #0000000d;
}

.testimonials-card-message {
  opacity: .35;
  color: #666;
  font-size: 18px;
  line-height: 1.6 !important;
}

.testimonials-cards .center .testimonials-card-message-container {
  background-color: #09853d;
}

.testimonials-cards .center .testimonials-card-message-container:before {
  content: "";
  width: 0;
  height: 0;
  border: 9px solid #0000;
  border-color: #09853d #0000 #0000 #09853d;
  margin-left: -10px;
  display: block;
  position: absolute;
  top: auto;
  bottom: -18px;
  left: 45px;
}

.testimonials-cards .center .testimonials-card-message-container .testimonials-card-message {
  color: #fff;
  opacity: 1;
}

.testimonials-cards .center .testimonials-card-author {
  opacity: 1;
}

.testimonials-card-author-img {
  float: left;
  max-width: 100px;
}

.testimonials-card-author-img img {
  width: 70%;
  border-radius: 50px;
}

.testimonials-card-author-name {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
}

.section-testimonials a {
  color: #fff;
  text-decoration: underline;
}

.section-testimonials a:hover {
  color: #e5e7f2;
  text-decoration: none;
}

.testimonials-card-author-description {
  line-height: 1.6;
}

.infra-images {
  max-width: 25%;
  flex: 25%;
  padding: 0 4px;
}

.infra-images img {
  vertical-align: middle;
  margin-top: 8px;
}

@media screen and (width <= 800px) {
  .infra-images {
    max-width: 50%;
    flex: 50%;
  }
}

@media screen and (width <= 600px) {
  .infra-images {
    max-width: 100%;
    flex: 100%;
  }
}

.fact {
  text-align: center;
}

.fact-icon {
  color: #007e37;
  font-size: 48px;
}

.fact-number {
  margin-bottom: 10px;
  font-size: 42px;
}

.fact-text {
  font-size: 20px;
}

.section-contact {
  padding: 0 !important;
}

.contact-image {
  background-size: cover;
  background-image: url("https://img.freepik.com/free-photo/beautiful-woman-working-office_329181-8856.jpg?w=2000&t=st=1682340003~exp=1682340603~hmac=a7d87cea2bf7ad231e2980a032d7473bb1e6cccfa6b5419bc16593e35473333f") !important;
  padding-top: 650px !important;
}

.contact-image-container {
  float: left;
  padding: 0;
}

.section-contact input, textarea {
  width: 100%;
  background-color: #0000;
  border: 0 solid #9b9b9b80;
  border-bottom-width: 1px;
  outline: 0;
  padding: 1em;
}

.section-contact .is-invalid {
  border: 0 solid #ff000080;
  border-bottom-width: 1px;
}

.section-contact .is-invalid::placeholder {
  color: red;
}

.section-map {
  padding: 0 !important;
}

.map-info-container {
  z-index: 10;
  width: 90vw;
  background: #fff;
  border: 1px solid #e5e7f2;
  border-radius: 4px;
  margin: 15px auto;
  padding: 40px 40px 15px;
  box-shadow: 0 5px 40px #00000012;
}

.section-map address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857;
}

.map-info-detail-row {
  color: #828282;
  margin-bottom: 25px;
  line-height: 1;
  display: block;
}

.map-info-details .fas, .map-info-details .fab {
  margin-right: 10px;
}

.footer {
  width: 100%;
  color: #e5e7f2;
  background-color: #008b3a;
  padding: 20px;
  font-size: small;
  font-weight: 600;
  line-height: 20px;
  bottom: 0;
}

.footer a {
  color: #e5e7f2;
  text-decoration: underline;
}

.footer a:hover {
  text-decoration: none;
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: .5;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
}

#loadingScreen {
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#loadingText {
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

#btn-contact-form-submit.loading span {
  display: none;
  top: -50px;
  right: 0;
}

#btn-contact-form-submit {
  transition: all .1s ease-in;
}

#btn-contact-form-submit.loading {
  width: 60px;
  pointer-events: none;
  border-radius: 50%;
  transition: all .1s ease-out;
  animation: 1s infinite bounce;
  display: inline-block !important;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    height: 60px;
    transform: translateY(0);
  }

  40% {
    height: 68px;
    transform: translateY(-30px);
  }

  60% {
    height: 68px;
    transform: translateY(-15px);
  }
}

/*# sourceMappingURL=index.47aaf7ae.css.map */
