/**
 * ---------------------------------------------------
 * Starter Template - Um template com estrutura inicial para um projeto básico front-end.
 *
 * @author Copyright 2018 RaphaelRDSoares <raphael@rdsoares.com>
 * @license https://en.wikipedia.org/wiki/MIT_License
 * @see https://github.com/raphaelrdsoares/starter-template
 * @version 0.1.0
 * ---------------------------------------------------
 */

html {
    min-height: 100%;
}

body {
    padding-top: 5rem;
    /* font-family: "Raleway", sans-serif; */
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

/* ================ NAVBAR ================ */
.navbar {
    background-color: #09853d;
}
.navbar .dropdown-menu {
    background-color: #09853d;
    border: 0px;
}
.navbar .dropdown-item {
    color: white;
    opacity: 0.5;
    font-size: 13px;
    font-family: "Open Sans";
    letter-spacing: 1px;
}
.navbar .dropdown-item:hover {
    opacity: 0.75;
    background-color: inherit;
}
.navbar-brand {
    font-weight: 400;
    font-family: Marcellus;
    font-size: 30px;
}
.nav-item {
    font-size: 13px;
    font-family: "Open Sans";
    padding: 5px 5px;
    letter-spacing: 1px;
}

/* ================ HERO ================ */

.section-hero-bg {
    background-image: url("../images/home.jpg");
    background-size: cover;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -ms-filter: blur(2px);
    -o-filter: blur(2px);
    filter: blur(2px);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    top: auto !important;
}

.hero {
    /* The image used */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../images/home2.jpg"); */

    /* Background styles */
    /* background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed; */

    /* Sizing */
    width: 100%;
    height: 100vh;

    /* Flexbox stuff */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Text styles */
    text-align: center;
    color: white;

    /* Margins */
    margin-top: -3em;
}

.hero-title {
    /* Text styles */
    font-size: 5em;
    font-family: Marcellus;
    font-weight: 400;

    /* Margins */
    margin-bottom: 0.5em;
    margin-top: 75px;
}

.hero-button {
    background-color: #09853d;
    border: 2px solid #007e37;
    position: sticky;
    display: block;
    overflow: hidden;
    max-width: 250px;
    margin: 1rem auto;
    text-align: center;
    letter-spacing: 2px;
    white-space: nowrap;
    padding: 10px 0px;
}
.hero-button:hover {
    background: transparent;
    color: white;
    border: 2px solid white;
}

.hero-button-outline {
    background: transparent;
    color: white;
    border: 2px solid white;
    position: sticky;
    display: block;
    overflow: hidden;
    max-width: 250px;
    margin: 1rem auto;
    text-align: center;
    letter-spacing: 2px;
    white-space: nowrap;
    padding: 10px 0px;
}
.hero-button-outline:hover {
    background-color: #09853d;
    border: 2px solid #007e37;
}

/* ================ SECTION ================ */

.section {
    margin: 0rem 0rem;
    padding: 3rem 1rem;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.section-header {
    text-align: center;
    margin-bottom: 60px;
}
.section-title {
    font-size: 34px;
    font-weight: 300 !important;
}
.section-title-separator {
    border: #09853d solid 1px;
    width: 40px;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 10px;
}
.section-bg-gray {
    background-color: #f3f3f7;
}
.section-bg-dark {
    color: white;
    background-color: black;
}

.text-big {
    font-weight: 200;
    font-size: 25px;
}
.text-medium {
    font-size: 18px;
    font-weight: 200;
}

.section-separator {
    padding: 100px 50px;
}

/* ================ SUBSECTION ================ */
.subsection-header {
    width: 100% !important;
    margin-left: -15px;
}

.subsection-title {
    color: #666;
    font-size: 28px;
    margin-top: 10px;
}

.subsection-title-separator {
    border: #666 solid 1px;
    width: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.subsection-body {
    margin-top: 20px;
}

/* ================ SERVICES ================ */
.service-card {
    background-color: #fff;
    padding: 40px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    text-align: left;
    overflow: hidden;
    padding-bottom: 35px;
    margin-bottom: 30px;
}

.service-card-image {
    max-width: 100%;
    max-height: 100%;
    margin-right: 0;
    display: block;
    margin: auto;
    text-align: center;
    float: none;
    height: auto;
    margin-bottom: 15px;
}

.service-card-image img {
    border-radius: 4px;
    float: none;
}

.service-card-title {
    vertical-align: top;
    text-align: center;
    margin-bottom: 16px;
}

.service-card-body {
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
    margin-bottom: 0;
    line-height: 1.6;
    color: #828282;
    font-size: 14px;
    text-align: justify;
}
.service-card-body p {
    margin-bottom: 0px;
}

/* ================ HEALTH INSURANCES ================ */
.group-image-container {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
}
.group-image-container:last-of-type {
    border-right-width: 0px;
}

.section-health-insurances img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
    filter: grayscale(100%);
    transition: all 0.5s ease;
}
.section-health-insurances img:hover {
    filter: grayscale(0%);
    transition: all 0.5s ease;
}

/* ================ TEAM ================ */
.subsection-team {
    padding: 0px 20px;
}
.subsection-team .subsection-header {
    padding-left: 5px;
    background-color: white;
}
.card-team {
    padding: 0px 0px 40px 0px;
}

.card-team-img {
    image-orientation: from-image;
}

.card-team-subtitle {
    opacity: 0.7;
    font-weight: 600;
    font-size: 14px;
}

.card-team-body {
    padding: 30px;
}

.card-team-footer {
    color: #828282;
    margin-top: 20px;
    font-size: 14px;
    opacity: 0.7;
}
.card-team-social-icon {
    margin-right: 20px;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    /* width: 300px;
    */
    /* height: 586px; */
    height: 610px;
    /* height: 760px; */
    /* border: 1px solid #f1f1f1; */
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
/* .flip-card:hover .flip-card-inner { */
/* transform: rotateY(0deg); */
/* } */

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    /* width: 100%;
    height: 100%; */
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-position: center;
    height: 350px;
    background-size: cover;
    width: 100%;
    /* color: black; */
}
.flip-card-front .card-body {
    padding-top: 370px;
}

/* Style the back side */
.flip-card-back {
    /* color: white; */
    height: 100%;
    transform: rotateY(180deg);
}

.flip-card-back .card-body {
    height: 100%;
}

/* ================ TESTIMONIALS ================ */
.section-testimonials-bg {
    background-image: url("../images/testimonials/bg.jpg");
    background-size: cover;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -ms-filter: blur(2px);
    -o-filter: blur(2px);
    filter: blur(2px);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.testimonials-cards {
    overflow: hidden;
    position: relative;
    z-index: 2;
    width: 100%;
}
.testimonials-card {
    padding: 0 15px;
    /* width: 60% !important; */
    max-width: 800px;
}
@media screen and (max-width: 600px) {
    /* .testimonials-card {
        max-width: 100vw;
    } */
}

.testimonials-card-message-container {
    position: relative;
    border: none;
    border-radius: 0;
    padding: 40px;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: left;
    background-color: white;
    border-radius: 4px;
    margin-top: 20px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
}
.testimonials-card-message {
    line-height: 1.6 !important;
    opacity: 0.35;
    font-size: 18px;
    color: #666;
}

.testimonials-cards .center .testimonials-card-message-container {
    background-color: #09853d;
}
.testimonials-cards .center .testimonials-card-message-container::before {
    bottom: -18px;
    top: auto;
    border-color: #09853d transparent transparent #09853d;
    content: "";
    display: block;
    position: absolute;
    left: 45px;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px;
}
.testimonials-cards .center .testimonials-card-message-container .testimonials-card-message {
    color: white;
    opacity: 1;
}
.testimonials-card-author {
    /* opacity: 0; */
}
.testimonials-cards .center .testimonials-card-author {
    opacity: 1;
}
.testimonials-card-author-img {
    float: left;
    max-width: 100px;
}
.testimonials-card-author-img img {
    width: 70%;
    border-radius: 50px;
}
.testimonials-card-author-name {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 2px;
}
.section-testimonials a {
    color: #ffffff;
    text-decoration: underline;
}
.section-testimonials a:hover {
    color: #e5e7f2;
    text-decoration: none;
}
.testimonials-card-author-description {
    line-height: 1.6;
}

/* ================ INFRA ================ */
/* Create four equal columns that sits next to each other */
.infra-images {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.infra-images img {
    margin-top: 8px;
    vertical-align: middle;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .infra-images {
        flex: 50%;
        max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .infra-images {
        flex: 100%;
        max-width: 100%;
    }
}

/* ================ FACTS ================ */
.fact {
    text-align: center;
}
.fact-icon {
    font-size: 48px;
    color: #007e37;
}
.fact-number {
    font-size: 42px;
    margin-bottom: 10px;
}
.fact-text {
    font-size: 20px;
}

/* ================ CONTACT ================ */
.section-contact {
    padding: 0 !important;
}

.contact-image {
    padding-top: 650px !important;
    /* background-image: url("http://raphael.rdsoares.com/projetos/reabilit/wp-content/uploads/2018/08/final.jpg?id=6547") !important; */
    /* background-image: url("https://img.freepik.com/free-photo/beautiful-young-female-doctor-looking-camera-office_1301-7781.jpg?w=2000&t=st=1682339878~exp=1682340478~hmac=a99a405d58701c8ce364989d6b3aada027aae21d2e6a9af766d1d8726e4eb61e") !important; */
    background-image: url("https://img.freepik.com/free-photo/beautiful-woman-working-office_329181-8856.jpg?w=2000&t=st=1682340003~exp=1682340603~hmac=a7d87cea2bf7ad231e2980a032d7473bb1e6cccfa6b5419bc16593e35473333f") !important;
    background-size: cover;
}
.contact-image-container {
    padding: 0px;
    float: left;
}

.section-contact input,
textarea {
    width: 100%;
    padding: 1em;
    border: 0 solid rgba(155, 155, 155, 0.5);
    outline: 0;
    background-color: transparent;
    border-bottom-width: 1px;
}
.section-contact .is-invalid {
    border: 0 solid rgba(255, 0, 0, 0.5);
    border-bottom-width: 1px;
}
.section-contact .is-invalid::placeholder {
    color: red;
}
/* 
.section-contact button {
    background-color: #09853d;
    border: 1px solid #007e37;
    position: sticky;
    display: block;
    overflow: hidden;
    margin: 1rem auto;
    text-align: center;
    margin-top: 2.5rem;
    letter-spacing: 2px;
    white-space: nowrap;
    padding: 10px 0px;
}
.section-contact button:active,
.section-contact button:focus {
    background-color: #09853d !important;
    border: 1px solid rgb(0, 126, 55) !important;
}
.section-contact button:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 55, 0.5);
}
.section-contact button:hover {
    background-color: #09853d !important;
    color: white;

    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
} */
/* ================ MAP ================ */
.section-map {
    padding: 0px !important;
}

.map-info-container {
    z-index: 10;
    width: 90vw;
    padding: 40px;
    margin: 15px auto;
    background: #ffffff;
    border: 1px solid #e5e7f2;
    border-radius: 4px;
    padding-bottom: 15px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.07);
}

.section-map address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42857143;
}
.map-info-detail-row {
    display: block;
    margin-bottom: 25px;
    line-height: 1;
    color: #828282;
}
.map-info-details .fas {
    margin-right: 10px;
}
.map-info-details .fab {
    margin-right: 10px;
}

/* ================ FOOTER ================ */
.footer {
    bottom: 0;
    width: 100%;
    line-height: 20px;
    padding: 20px;
    color: #e5e7f2;
    background-color: #008b3a;
    font-weight: 600;
    font-size: small;
}
.footer a {
    color: #e5e7f2;
    text-decoration: underline;
}
.footer a:hover {
    text-decoration: none;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
}

/* ================ Loading screen ================ */
#loadingScreen {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Disable scrolling */
}

#loadingText {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

#btn-contact-form-submit.loading span {
    top: -50px;
    right: 0;
    display: none;
}

#btn-contact-form-submit {
    transition: ease-in 0.1s;
}

#btn-contact-form-submit.loading {
    width: 60px;
    border-radius: 50%;
    animation: bounce 1s infinite;
    pointer-events: none;
    display: inline-block !important;
    transition: ease-out 0.1s;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
        height: 60px;
    }
    40% {
        transform: translateY(-30px);
        height: 68px;
    }
    60% {
        transform: translateY(-15px);
        height: 68px;
    }
}
